import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Team from '../components/Team'

const TeamPage = () => (
    <Layout>
        <SEO title="team:title" />
        <Team />
    </Layout>
)

export default TeamPage
